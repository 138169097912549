import { Accordion, Card, Flex, FormLayout, generateTypedFormComponents, spacing } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { voicePersonaBehaviors, voicePersonaTools } from './VoicePersonaEditor.schema';
import type { voicePersonaEditorSchema } from './VoicePersonaEditor.schema';

const { InputGroup } = generateTypedFormComponents<typeof voicePersonaEditorSchema>();

export const VoicePersonaEditor: FC = () => {
    return (
        <Flex flexDirection="column" gap={spacing.s20}>
            <InputGroup
                inputComponent="Select"
                label="Persona Revision"
                mode="single"
                placeholder="Search"
                schemaKey="personaRevisionPublicId"
            />
            <Accordion isOpenByDefault title="Base Prompt">
                <Card>
                    <FormLayout>
                        <InputGroup
                            autoExpanding
                            disabled
                            inputComponent="TextArea"
                            label="Personality"
                            placeholder="Personality"
                            schemaKey="personalityInstructions"
                        />
                        <InputGroup
                            autoExpanding
                            disabled
                            inputComponent="TextArea"
                            label="Base Instructions"
                            placeholder="Instructions"
                            schemaKey="baseInstructions"
                        />
                        <InputGroup
                            disabled
                            inputComponent="Input"
                            label="User Info Prefix"
                            placeholder="User Info Prefix"
                            schemaKey="basicUserInfoInstructions"
                        />
                        <InputGroup
                            disabled
                            inputComponent="Input"
                            label="Customer Card Prefix"
                            placeholder="Customer Card Prefix"
                            schemaKey="detailedCustomerCardInstructions"
                        />
                    </FormLayout>
                </Card>
            </Accordion>
            <Accordion isOpenByDefault title="Defined Behaviors">
                <Card>
                    <FormLayout>
                        {voicePersonaBehaviors.map(b => (
                            <InputGroup
                                autoExpanding
                                disabled
                                inputComponent="TextArea"
                                key={b}
                                label={b}
                                placeholder={b}
                                schemaKey={`behaviors.${b}`}
                            />
                        ))}
                    </FormLayout>
                </Card>
            </Accordion>
            <Accordion isOpenByDefault title="Tools Descriptions">
                <Card>
                    <FormLayout>
                        {voicePersonaTools.map(t => (
                            <InputGroup
                                autoExpanding
                                disabled
                                inputComponent="TextArea"
                                key={t}
                                label={t}
                                placeholder={t}
                                schemaKey={`tools.${t}.description`}
                            />
                        ))}
                    </FormLayout>
                </Card>
            </Accordion>
            <Accordion isOpenByDefault title="Other Settings">
                <Card>
                    <FormLayout>
                        <InputGroup
                            disabled
                            inputComponent="Select"
                            label="Voice"
                            placeholder="Voice"
                            schemaKey="properties.voice"
                        />
                        <InputGroup
                            disabled
                            inputComponent="Input"
                            label="User speech detection threshold"
                            placeholder="The higher this is, the louder the user needs to speak to be recognized"
                            schemaKey="properties.userSpeechDetectionThreshold"
                            type="number"
                        />
                        <InputGroup
                            disabled
                            inputComponent="Input"
                            label="User speech start padding (milliseconds)"
                            placeholder="How long the user needs to speak before being recognized"
                            schemaKey="properties.userSpeechStartPaddingDuration"
                            type="number"
                        />
                        <InputGroup
                            disabled
                            inputComponent="Input"
                            label="User speech end padding (milliseconds)"
                            placeholder="How long the user needs to pause speaking before speech is considered over"
                            schemaKey="properties.userSpeechEndSilenceDuration"
                            type="number"
                        />
                        <InputGroup
                            disabled
                            inputComponent="Input"
                            label="Temperature"
                            placeholder="Temperature"
                            schemaKey="properties.temperature"
                            type="number"
                        />
                    </FormLayout>
                </Card>
            </Accordion>
        </Flex>
    );
};
