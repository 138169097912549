/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { OpenAIRealtime, PersonaRevisionStatus, WellKnownPersonaToolName } from '@lemonade-hq/voice-conversations';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { blenderGeneral } from '../../../apiClients';
import type { VoiceConversation, VoicePersonaRevision } from './Voice.types';

const BASE_URL = '/api/v1/voice';

export enum VoiceQueryKey {
    GetConversation = 'GET_CONVERSATION',
    ListConversations = 'LIST_CONVERSATIONS',
    GetPersonaRevision = 'GET_PERSONA_REVISION',
    GetPublishedPersonaRevision = 'GET_PUBLISHED_PERSONA_REVISION',
}

export const CONVERSATION_PLACEHOLDER: VoiceConversation = {
    publicId: 'mock-public-id',
    startedAt: '2024-01-01:00:00.000Z',
    endedAt: '2024-01-01:00:00.3000Z',
    personaRevisionPublicId: '',
    toolsRevisionPublicId: '',
    inboundExternalId: '',
    outboundExternalId: '',
    timeline: [],
    transcript: [
        { content: 'mock', side: 'assistant', startedAt: 0, endedAt: 15_000, id: 'mock' },
        { content: 'mock', side: 'user', startedAt: 15_000, endedAt: 3000, id: 'mock' },
    ],
    recordingFilePublicId: '',
};

export const PERSONA_REVISION_PLACEHOLDER: VoicePersonaRevision = {
    addedAt: '',
    publicId: '',
    status: PersonaRevisionStatus.Published,
    addedBy: '',
    config: {
        baseInstructions: '',
        basicUserInfoInstructions: '',
        detailedCustomerCardInstructions: '',
        behaviors: {
            greeting: '',
            toolCallInitialLingeringNotification: '',
            absenceGoodbye: '',
            absenceNotification: '',
            assistantEscalation: '',
            conversationTooLongGoodbye: '',
            explicitEscalation: '',
            fulfillmentGoodbye: '',
            toolCallContinuedLingeringNotification: '',
            toolCallFailed: '',
        },
        personalityInstructions: '',
        properties: {
            temperature: 0.6,
            userSpeechDetectionThreshold: 500,
            userSpeechEndSilenceDuration: 500,
            userSpeechStartPaddingDuration: 500,
            voice: OpenAIRealtime.Voice.Alloy,
        },
        tools: {
            [WellKnownPersonaToolName.Answer]: { description: '' },
            [WellKnownPersonaToolName.ExplicitEscalation]: { description: '' },
            [WellKnownPersonaToolName.FulfillConversation]: { description: '' },
        },
    },
};

// conversations

export function useGetConversation(
    conversationPublicId: string | undefined,
    { enabled, staleTime }: { readonly enabled?: boolean; readonly staleTime?: number } = {}
): UseQueryResult<VoiceConversation> {
    return useQuery({
        enabled: enabled ?? conversationPublicId != null,
        placeholderData: CONVERSATION_PLACEHOLDER,
        queryKey: [VoiceQueryKey.GetConversation, conversationPublicId],
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: staleTime ?? Infinity,
        queryFn: async () => {
            const url = `${BASE_URL}/conversations/${conversationPublicId}`;
            const response = await blenderGeneral.get<{ readonly data: VoiceConversation }>(url);
            return response.data.data;
        },
    });
}

export function useListConversations(): UseQueryResult<VoiceConversation[]> {
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: [VoiceQueryKey.GetConversation],
        placeholderData: [CONVERSATION_PLACEHOLDER, CONVERSATION_PLACEHOLDER, CONVERSATION_PLACEHOLDER],
        queryFn: async () => {
            const url = `${BASE_URL}/conversations`;
            const response = await blenderGeneral.get<{ readonly data: VoiceConversation[] }>(url);
            const conversations = response.data.data;
            conversations.forEach(conversation => {
                queryClient.setQueryData([VoiceQueryKey.GetConversation, conversation.publicId], conversation);
            });
            return conversations;
        },
    });
}

// persona revisions

export function useGetPublishedPersonaRevision({
    enabled,
}: {
    readonly enabled?: boolean;
} = {}): UseQueryResult<VoicePersonaRevision> {
    const queryClient = useQueryClient();

    return useQuery({
        enabled: enabled ?? true,
        queryKey: [VoiceQueryKey.GetPublishedPersonaRevision],
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        placeholderData: PERSONA_REVISION_PLACEHOLDER,
        queryFn: async () => {
            const url = `${BASE_URL}/persona_revisions/published`;
            const response = await blenderGeneral.get<{ readonly data: VoicePersonaRevision }>(url);
            const publishedPersonaRevision = response.data.data;
            queryClient.setQueryData(
                [VoiceQueryKey.GetPersonaRevision, publishedPersonaRevision.publicId],
                publishedPersonaRevision
            );
            return publishedPersonaRevision;
        },
    });
}
