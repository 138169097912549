/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
    CardGrid,
    CardGridArea,
    FormProvider,
    IconButton,
    overflowYContainer,
    pageWrapper,
    shimmering,
    spacing,
    Text,
    Tooltip,
} from '@lemonade-hq/blender-ui';
import { Flex } from '@lemonade-hq/cdk';
import { clsx } from 'clsx';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetPublishedToolsRevision, useListPendingToolsRevisions } from '../Playground/persisted_tools.queries';
import { VoicePersonaEditor } from './simulation/VoicePersonaEditor';
import {
    getInitialFormConfig,
    getInitialFormValues,
    voicePersonaEditorSchema,
} from './simulation/VoicePersonaEditor.schema';
import { VoiceSimulation } from './simulation/VoiceSimulation';
import { useGetPublishedPersonaRevision } from './Voice.queries';
import { VOICE_FULL_PATH_PREFIX } from './Voice.Routes';
import { useForceBlenderUI } from 'hooks/useForceBlenderUI';
import { useFullscreen } from 'hooks/useFullScreen';

export const VoiceSimulationPage: FC = () => {
    useFullscreen();
    useForceBlenderUI();
    const navigate = useNavigate();

    // TODO: disable editing schema while simulating call

    const { data: publishedToolsRevision, isPlaceholderData: isLoadingPublishedToolsRevision } =
        useGetPublishedToolsRevision();
    const { data: pendingToolsRevisions, isPlaceholderData: isLoadingPendingToolsRevisions } =
        useListPendingToolsRevisions();
    const { data: publishedPersonaRevision, isPlaceholderData: isLoadingPublishedPersonaRevision } =
        useGetPublishedPersonaRevision();

    const isLoading =
        isLoadingPublishedToolsRevision || isLoadingPendingToolsRevisions || isLoadingPublishedPersonaRevision;

    return (
        <Flex
            className={clsx(pageWrapper, { [shimmering]: isLoading })}
            gap={spacing.s16}
            height="100%"
            padding={spacing.s16}
        >
            <FormProvider
                globallyDisabled={isLoading}
                initialConfig={getInitialFormConfig(
                    publishedToolsRevision,
                    pendingToolsRevisions,
                    publishedPersonaRevision
                )}
                initialValues={getInitialFormValues(publishedToolsRevision, publishedPersonaRevision)}
                key={isLoadingPublishedToolsRevision.toString()}
                schema={voicePersonaEditorSchema}
            >
                <CardGrid
                    areas={[['header'], ['content']]}
                    flexBasis="40rem"
                    flexGrow={0}
                    flexShrink={0}
                    gridTemplateRows="min-content"
                    showSeparators
                >
                    <CardGridArea alignItems="center" areaName="header" display="flex">
                        <Flex alignItems="center" gap={spacing.s10}>
                            <Tooltip content="Back to conversations page" side="bottom">
                                <IconButton
                                    aria-label="back"
                                    icon="chevron-down"
                                    onClick={() => navigate(VOICE_FULL_PATH_PREFIX)}
                                    rotation="cw90deg"
                                    size="md"
                                    variant="inline"
                                />
                            </Tooltip>
                            <Text type="h4">Phone Call Simulation</Text>
                        </Flex>
                    </CardGridArea>
                    <CardGridArea areaName="content" padding={spacing.s16}>
                        {isLoading ? null : <VoiceSimulation />}
                    </CardGridArea>
                </CardGrid>
                <CardGrid areas={[['header'], ['content']]} gridTemplateRows="min-content" showSeparators>
                    <CardGridArea alignItems="center" areaName="header" display="flex">
                        <Text type="h4">Persona Editor</Text>
                    </CardGridArea>
                    <CardGridArea
                        areaName="content"
                        className={overflowYContainer({ paddingBlock: 's16' })}
                        overflow="auto"
                        padding={spacing.s16}
                    >
                        <VoicePersonaEditor />
                    </CardGridArea>
                </CardGrid>
            </FormProvider>
        </Flex>
    );
};
