import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PlaygroundComparePage } from '../PlaygroundComparePage';
import { PlaygroundIndexPage } from '../PlaygroundIndexPage';
import { PlaygroundPage } from '../PlaygroundPage';
import {
    PLAYGROUND_COMPARE_BASE_PATH,
    PLAYGROUND_COMPARE_PATH_WITH_PARAMS,
    PLAYGROUND_COMPARE_PATH_WITH_PARAMS_AND_TOOL,
    PLAYGROUND_PATH_WITH_TOOL,
    PLAYGROUND_PATH_WITHOUT_TOOL,
} from './routing.consts';

export const PlaygroundRoutes: FC = () => {
    return (
        <Routes>
            <Route element={<PlaygroundIndexPage />} path="/" />
            {[
                PLAYGROUND_COMPARE_BASE_PATH,
                PLAYGROUND_COMPARE_PATH_WITH_PARAMS,
                PLAYGROUND_COMPARE_PATH_WITH_PARAMS_AND_TOOL,
            ].map(path => (
                <Route element={<PlaygroundComparePage />} key={path} path={path} />
            ))}
            {[PLAYGROUND_PATH_WITHOUT_TOOL, PLAYGROUND_PATH_WITH_TOOL].map(path => (
                <Route element={<PlaygroundPage />} key={path} path={path} />
            ))}
        </Routes>
    );
};
