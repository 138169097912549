/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/naming-convention */
import type { Config } from '@lemonade-hq/blender-ui';
import type { Infer } from '@lemonade-hq/maschema-schema';
import { ma, merge, va } from '@lemonade-hq/maschema-schema';
import type { SerializableToolsRevision } from '@lemonade-hq/persisted-tools';
import type { SerializablePersonaRevision } from '@lemonade-hq/voice-conversations';
import { cxPersonaRevisionConfigSchema } from '@lemonade-hq/voice-conversations';

// consts

const USERS_EXAMPLES = [
    'LU2109B3906',
    'LU2109DBE06',
    'LU3109E1705',
    'LU418EC3708',
    'LU418ED1808',
    'LU612B24793',
    'LU716D5F524',
    'LU716D7E424',
    'LU716D9E0E4',
    'LU8233F42B9',
    'LU916D8AC32',
    'LU916D8C7B2',
    'LU916D97A72',
] as const;

// schema

export const baseSettingsSchema = ma.record({
    toolsRevisionPublicId: ma.string(),
    personaRevisionPublicId: ma.string(),
    userPublicId: ma.optional.oneOf(USERS_EXAMPLES as unknown as string[]),
});

export const voicePersonaEditorSchema = merge(cxPersonaRevisionConfigSchema, baseSettingsSchema);

export const voicePersonaBehaviors = Object.keys(cxPersonaRevisionConfigSchema.schema.behaviors.schema) as (string &
    keyof Infer<typeof cxPersonaRevisionConfigSchema>['behaviors'])[];

export const voicePersonaTools = Object.keys(cxPersonaRevisionConfigSchema.schema.tools.schema) as (string &
    keyof Infer<typeof cxPersonaRevisionConfigSchema>['tools'])[];

// helpers

export const USERS_LABELS: Record<(typeof USERS_EXAMPLES)[number], string> = {
    LU2109B3906: 'LU2109B3906 - Renters with Bindable Quote',
    LU2109DBE06: 'LU2109DBE06 - Multiple Pet Policies',
    LU3109E1705: 'LU3109E1705 - Renters',
    LU418EC3708: 'LU418EC3708 - Renters & Car Fixed Policies',
    LU418ED1808: 'LU418ED1808 - Renters with Active Policy',
    LU716D5F524: 'LU716D5F524 - Renters Blocked User',
    LU612B24793: 'LU612B24793 - Pet',
    LU716D7E424: 'LU716D7E424 - Pet with Future Policy',
    LU716D9E0E4: 'LU716D9E0E4 - Pet and Renters',
    LU916D97A72: 'LU916D97A72 - Pet with Active and Future Policies',
    LU8233F42B9: 'LU8233F42B9 - Homeowners',
    LU916D8AC32: 'LU916D8AC32 - Car in Dunning',
    LU916D8C7B2: 'LU916D8C7B2 - Car PPM',
};

export function getInitialFormValues(
    toolsRevision: SerializableToolsRevision | undefined,
    personaRevision: SerializablePersonaRevision | undefined
): Partial<Infer<typeof voicePersonaEditorSchema>> {
    return {
        toolsRevisionPublicId: toolsRevision?.publicId ?? '',
        personaRevisionPublicId: personaRevision?.publicId ?? '',
        userPublicId: undefined,
        ...personaRevision?.config,
    };
}

export function getInitialFormConfig(
    publishedToolsRevision: SerializableToolsRevision | undefined,
    pendingToolsRevisions: SerializableToolsRevision[] | undefined,
    personaRevision: SerializablePersonaRevision | undefined
): Config<typeof voicePersonaEditorSchema> {
    return {
        additionalValidations: {
            toolsRevisionPublicId: [
                va.oneOf(
                    [publishedToolsRevision?.publicId, ...(pendingToolsRevisions?.map(t => t.publicId) ?? [])].filter(
                        Boolean
                    ) as string[]
                ),
            ],
            personaRevisionPublicId: [va.oneOf([personaRevision?.publicId].filter(Boolean) as string[])],
        },
    };
}
